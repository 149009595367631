@font-face {
  font-family: "OfficinaSansITCPro-Book";
  src: url("./assets/fonts/ITC-OfficinaSansITCPro-Book.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/ITC-OfficinaSansITCPro-Book.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/ITC-OfficinaSansITCPro-Book.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/ITC-OfficinaSansITCPro-Book.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/ITC-OfficinaSansITCPro-Book.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/ITC-OfficinaSansITCPro-Book.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/ITC-OfficinaSansITCPro-Book.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OfficinaSansITCPro-Bold";
  src: url("./assets/fonts/OfficinaSansITCPro-Bold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/OfficinaSansITCPro-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/OfficinaSansITCPro-Bold.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/OfficinaSansITCPro-Bold.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/OfficinaSansITCPro-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/OfficinaSansITCPro-Bold.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/OfficinaSansITCPro-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.pos-fixed {
  position: fixed !important;
  bottom: 0px !important;
}

.bottom {
  bottom: 0px !important;
  max-width: 1300px;
}

.font-weight-soft{
  font-weight: 300 !important;
}

.training-day-label {
  border-radius: 12px !important;
  border: 1px solid #51B89D !important;
}

.menu-logo {
  padding-left: 0px !important;
  width: 115px !important;
}

.mobile-menu-item {
  margin: 10px 0px !important;
  padding: 0px !important;
  padding-top: 8px !important;
}

@media (min-width: 768px) {
  .menu-logo {
    width: 170px !important;
  }
}
@media (min-width: 768px) {
  .audio-btn {
    max-width: 360px !important;
  }
}

.icon-img {
  border: 12px solid #0084BD;
  background-color: #0084BD !important;
}

.padded {
  margin: 8px !important;
}



.footer > a {
  color: white !important;
  padding: 0px 8px !important;  
}

.allButFooter {
  min-height: calc(100vh - 36px) !important;
  padding-top: 88px;
}

@media (max-width: 700px) {
  .allButFooter {
    padding-top: 64px;
  }
}

.bold {
  font-weight: bold;
}

.main-font {
  font-family: OfficinaSansITCPro-Book !important;
}

.no-margin {
  margin: 0px !important;
}

.exercise-text {
  padding: 8px 0px !important;
}

.audio-adjust-title {
  margin: 16px 0px !important;
}

.arrow-down {
  transform: rotate(180deg);
}

.right-padded {
  padding-right: 8px;
}

.color-green {
  background-color: #51B89D !important;
  color: #fff !important;
}

.cookieBanner {
  z-index: 1337;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  padding: 0;
  height: fit-content;
}
@media screen and (min-width: 767px) {
  .cookieBanner {
    padding: 0 20px;
  }
}

.cookieBanner .cookieBannerInner {
  max-width: 100%;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  pointer-events: all;
  height: fit-content;
}
@media screen and (min-width: 767px) {
  .cookieBanner .cookieBannerInner {
    max-width: 500px;
  }
}

.cookieBanner .headline {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: normal;
  margin: 0 0 10px 0;
}

.cookieBanner .text {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.cookieBanner .text a {
  color: #0082BA;
  text-decoration: underline;
}

.cookieBanner .checkbox {
  margin-bottom: 10px;
}
.cookieBanner .buttonContainer {
  display: flex;
  flex-direction: column-reverse; 
  justify-content: flex-start;
  margin-top: 26px;
}
@media screen and (min-width: 767px) {
  .cookieBanner .buttonContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}

.cookieBanner .button {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 767px) {
  .cookieBanner .button {
    margin-bottom: 0;
    text-align: left;
    width: auto;
  }
}
.cookieBanner .button.accept-all-btn{
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 767px) {
  .cookieBanner .button.accept-all-btn{
    margin-bottom: 0 !important;
  }
}
.cookieBanner .button:first-of-type {
  margin-right: 20px;
}

.cookieBanner .formWrapper {
  display: flex;
  flex-direction: column;
}

.cookieBanner .formWrapper .checkboxLabel {
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;

}
@media screen and (min-width: 767px) {
  .cookieBanner .formWrapper .checkboxLabel {
    font-size: 10px;
  }
}

.cookieBanner .formWrapper .checkboxWrapper {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 29px;
  margin: 0 10px 10px 0;

}
@media screen and (min-width: 767px) {
  .cookieBanner .formWrapper .checkboxWrapper {
    width: 34px;
    height: 21px;
  }
}

.cookieBanner .formWrapper input {
  opacity: 0;
  width: 0;
  height: 0;

}
.cookieBanner .formWrapper input:checked + .slider {
  background-color: #21364e;
}

.cookieBanner .formWrapper input:focus + .slider {
  box-shadow: 0 0 1px #21364e;
}

.cookieBanner .formWrapper input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
@media screen and (min-width: 767px) {
  .cookieBanner .formWrapper input:checked + .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }
}

.cookieBanner .formWrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e3e2e1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.cookieBanner .formWrapper .slider:before {
  position: absolute;
  content: '';
  width: 23px;
  height: 23px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

@media screen and (min-width: 767px) {
  .cookieBanner .formWrapper .slider:before {
    height: 17px;
    width: 17px;
    bottom: 2px;
  }
}
